<template>
  <div class="content">
    <div class="zzhead">
      <div class="rule" @click="rules = true">规则</div>
      <p class="headtime">本期活动时间：{{ listperiod.period }}</p>
      <div class="headtitle">
        <div>
          <p>
            本期获得活跃值<img
              src="https://ppyos.xijiuyou.com/icon/fire-icon.png"
              alt=""
            />
          </p>
          <p>{{ listperiod.activity }}</p>
          <p @click="rankinglink">赚取活跃值</p>
        </div>
        <div></div>
        <div>
          <p>当前名次</p>
          <p>{{ listperiod.rankNo ? listperiod.rankNo : "暂未上榜" }}</p>
          <p @click="rankinglist()">领取排名奖励</p>
        </div>
      </div>
    </div>
    <div class="listranking">
      <div class="listrankingtitle">
        <p
          :style="
            checktable == 0
              ? 'background:url(https://ppyos.xijiuyou.com/imgs/color-banner.png) no-repeat;background-size: 100% 100%;color:#ffffff;'
              : ''
          "
          @click="tablecheck(0)"
        >
          本期排名
        </p>
        <p
          :style="
            checktable == 1
              ? 'background:url(https://ppyos.xijiuyou.com/imgs/color-banner.png) no-repeat;background-size: 100% 100%;color:#ffffff;'
              : ''
          "
          @click="tablecheck(1)"
        >
          上一期排名
        </p>
      </div>
      <!-- 排名 -->
      <div class="rankingtop">
        <div>
          <p>{{ starttop1[1] ? starttop1[1].useridStr : "-" }}</p>
          <p>
            {{ starttop1[1] ? starttop1[1].activity : "-"
            }}<img
              src="https://ppyos.xijiuyou.com/210913/coin-fire.png"
              alt=""
            />
          </p>
        </div>
        <div>
          <p>{{ starttop1[0] ? starttop1[0].useridStr : "-" }}</p>
          <p>
            {{ starttop1[0] ? starttop1[0].activity : "-"
            }}<img
              src="https://ppyos.xijiuyou.com/210913/coin-fire.png"
              alt=""
            />
          </p>
        </div>
        <div>
          <p>{{ starttop1[2] ? starttop1[2].useridStr : "-" }}</p>
          <p>
            {{ starttop1[2] ? starttop1[2].activity : "-"
            }}<img
              src="https://ppyos.xijiuyou.com/210913/coin-fire.png"
              alt=""
            />
          </p>
        </div>
      </div>

      <!-- 4-5名 -->
      <div class="fivetop">
        <div class="fever">
          <div><div>4</div></div>
          <p>{{ starttop1[3] ? starttop1[3].useridStr : "-" }}</p>
          <p>
            {{ starttop1[3] ? starttop1[3].activity : "-"
            }}<img
              src="https://ppyos.xijiuyou.com/210913/coin-fire.png"
              alt=""
            />
          </p>
        </div>
        <div class="fever">
          <div><div>5</div></div>
          <p>{{ starttop1[4] ? starttop1[4].useridStr : "-" }}</p>
          <p>
            {{ starttop1[4] ? starttop1[4].activity : "-"
            }}<img
              src="https://ppyos.xijiuyou.com/210913/coin-fire.png"
              alt=""
            />
          </p>
        </div>
        <div class="fever">
          <div><div>6</div></div>
          <p>{{ starttop1[5] ? starttop1[5].useridStr : "-" }}</p>
          <p>
            {{ starttop1[5] ? starttop1[5].activity : "-"
            }}<img
              src="https://ppyos.xijiuyou.com/210913/coin-fire.png"
              alt=""
            />
          </p>
        </div>
      </div>

      <div class="fivetop">
        <div class="fever rankingover">
          <div><div>7</div></div>
          <p>{{ starttop1[6] ? starttop1[6].useridStr : "-" }}</p>
          <p>
            {{ starttop1[6] ? starttop1[6].activity : "-"
            }}<img
              src="https://ppyos.xijiuyou.com/210913/coin-fire.png"
              alt=""
            />
          </p>
        </div>
        <div class="fever rankingover">
          <div><div>8</div></div>
          <p>{{ starttop1[7] ? starttop1[7].useridStr : "-" }}</p>
          <p>
            {{ starttop1[7] ? starttop1[7].activity : "-"
            }}<img
              src="https://ppyos.xijiuyou.com/210913/coin-fire.png"
              alt=""
            />
          </p>
        </div>
        <div class="fever rankingover">
          <div><div>9</div></div>
          <p>{{ starttop1[8] ? starttop1[8].useridStr : "-" }}</p>
          <p>
            {{ starttop1[8] ? starttop1[8].activity : "-"
            }}<img
              src="https://ppyos.xijiuyou.com/210913/coin-fire.png"
              alt=""
            />
          </p>
        </div>
        <div class="fever rankingover">
          <div><div>10</div></div>
          <p>{{ starttop1[9] ? starttop1[9].useridStr : "-" }}</p>
          <p>
            {{ starttop1[9] ? starttop1[9].activity : "-"
            }}<img
              src="https://ppyos.xijiuyou.com/210913/coin-fire.png"
              alt=""
            />
          </p>
        </div>
      </div>
      <!-- 列表排名 -->
      <div v-for="(item, index) in list" :key="index">
        <div class="ranking-list" @click="reanking(index)">
          <div>{{ item.title }}</div>
          <div>
            最低<span>{{ item.minActivity }}</span
            ><img
              src="https://ppyos.xijiuyou.com/icon/fire-icon.png"
              alt=""
            />活跃值<img
              src="https://ppyos.xijiuyou.com/210913/down-grey.jpg"
              alt=""
              class="rightimg"
            />
          </div>
        </div>
        <xmp v-if="pailist.length > 0 && index == checkindex" style="margin: 0">
          <div class="listarr"  v-for="(_item, _index) in pailist" :key="_index">
            <div>
              <p>{{_item.rank}}</p>
              <p>{{_item.useridStr}}</p>
            </div>
            <div>
              <span>{{_item.activity}}</span
              ><img
                src="https://ppyos.xijiuyou.com/210913/coin-fire.png"
                alt=""
              />
            </div>
          </div>
          <div class="gengduo" v-if="index>2 && fivezero" @click="more(index)">查看更多</div>
        </xmp>
      </div>
      <div></div>
      <!-- 刷新 -->
      <div class="Refresh">榜单5分钟刷新一次</div>
    </div>
    <!-- 榜单奖励 -->
    <div class="over">
      <div class="overhead">
        <img
          src="https://ppyos.xijiuyou.com/210913/process-2.jpg"
          alt=""
        />
        <p>榜单奖励</p>
        <img
          src="https://ppyos.xijiuyou.com/210913/process-1.jpg"
          alt=""
        />
      </div>
      <div class="overcontentbottom">
        <div
          class="overbottom"
          v-for="(item, index) in rankConfigList"
          :key="index"
          :style="'background:url(' + item.icon + ') 0% 0% / 100% 100%;'"
        >
          <p>
            <span
              :style="
                index > 0
                  ? 'color: #FF5675;'
                  : index > 2
                  ? 'color:rgba(255, 89, 40, 1);'
                  : ''
              "
              >¥</span
            ><span
              :style="
                index > 0
                  ? 'color: #FF5675;'
                  : index > 2
                  ? 'color:rgba(255, 89, 40, 1);'
                  : ''
              "
              >{{ item.money }}</span
            >
          </p>
          <p
            :style="
              index > 0
                ? 'background: rgba(204, 32, 39, 0.49);'
                : index > 2
                ? 'background:rgba(225, 62, 33, 0.49);'
                : ''
            "
          >
            {{ item.title }}
          </p>
        </div>
      </div>
    </div>
    <xwpopup :isshowpopup="rules" @close="closeHandle()" :hiddenClose="false">
      <div class="rulelist">
        <div class="rulelisthead">
          <p>规则</p>
          <img
            src="https://ppyos.xijiuyou.com/210913/money-couple.png"
            alt=""
          />
        </div>
        <div class="rulep">
          <p>
            <span>1.</span>
            <span
              >每日完成任务后可获得对应活跃值及随机红包奖励，红包及活跃值需自行领取，当日未领取则失效；指定任务每日0点更新</span
            >
          </p>
          <p>
            <span>2.</span>
            <span
              >周排行本周活跃值仅包含当周通过每日任务赚取的活跃值（不含夺宝、抽奖所获得的的活跃值），统计时间为本周一00:00-本周日23:59</span
            >
          </p>
          <p>
            <span>3.</span>
            <span
              >周排行奖励将根据周日23:59截止时的活跃值为准，活跃值相同以达到时间先后顺序排名</span
            >
          </p>
          <p>
            <span>4.</span>
            <span
              >排名奖励需自行领取，领取时间下周一00:00-下周日23:59，过期即无法领取；领取奖励不会扣减活跃值哦</span
            >
          </p>
          <p>
            <span>5.</span
            ><span>活跃值奖励将直接发放至您的账户，请注意查收</span>
          </p>
          <p><span>6.</span><span>活动最终解释权归泡泡鱼玩所有</span></p>
        </div>
      </div>
    </xwpopup>
  </div>
</template>

<script>
import xwpopup from "../components/Popupbox.vue";
import home from "../api/home";
import { Toast } from "vant";
export default {
  components: {
    xwpopup,
  },
  name: "list",
  data() {
    return {
      checktable: 0,
      rules: false,
      list: [],
      starttop1: [],
      pailist: [],
      checkindex: -1,
      gengindex: 0,
      fivezero: true,
      rankConfigList: [],
      listperiod: {},
    };
  },
  mounted() {
    this.rankinggetRanking();
    this.startrankinggetRankingData();
  },
  methods: {
    rankinglink() {
      // const router = this.$router.resolve({
      //   path: "/",
      // });
      //try{
         // window.android.backPre();
          window.location.href = "paopaoyu://app/page/main/index?tab=game&arg=-2,cpl";
      //   }catch(e){
      //     Toast('您当前app版本过低请更新app');
      // }
    },
    rankinglist() {
        try{
          window.android.createNewWeb(window.location.href.split("List")[0]+ 'ranking');
        }catch(e){
          Toast('您当前app版本过低请更新app');
        }
    },
    more(index) {
      //查看更多
      const that = this;
      let data;
      if (index == 3 && this.pailist.length < 200) {
        data = {
          end: 300,
          period: this.checktable,
          start: 201,
        };
      } else if (index == 3 && this.pailist.length < 300) {
        data = {
          end: 400,
          period: this.checktable,
          start: 301,
        };
      } else if (index == 3 && this.pailist.length < 400) {
        data = {
          end: 500,
          period: this.checktable,
          start: 401,
        };
        this.fivezero = false;
      } else if (index == 4 && this.pailist.length < 200) {
        data = {
          end: 700,
          period: this.checktable,
          start: 601,
        };
      } else if (index == 4 && this.pailist.length < 300) {
        data = {
          end: 800,
          period: this.checktable,
          start: 701,
        };
      } else if (index == 4 && this.pailist.length < 400) {
        data = {
          end: 900,
          period: this.checktable,
          start: 801,
        };
      } else {
        data = {
          end: 1000,
          period: this.checktable,
          start: 901,
        };
        this.fivezero = false;
      }
      home.rankinggetRankingData(data).then((res) => {
        that.pailist.push(...res.data.rankList);
      });
    },
    reanking(index) {
      //排名
      this.fivezero = true;
      this.checkindex = index;
      if (index == 0) {
        this.rankinggetRankingData(20, 11);
      } else if (index == 1) {
        this.rankinggetRankingData(30, 21);
      } else if (index == 2) {
        this.rankinggetRankingData(100, 31);
      } else if (index == 3) {
        this.rankinggetRankingData(200, 101);
      } else {
        this.rankinggetRankingData(600, 501);
      }
    },
    rankinggetRankingData(end, start) {
      //排名
      const data = {
        end: end,
        period: this.checktable,
        start: start,
      };
      home.rankinggetRankingData(data).then((res) => {
        this.pailist = res.data.rankList;
      });
    },
    startrankinggetRankingData() {
      //1-10名
      const data = {
        end: 10,
        period: this.checktable,
        start: 1,
      };
      home.rankinggetRankingData(data).then((res) => {
        this.starttop1 = res.data.rankList;
      });
    },
    rankinggetRanking() {
      //获取周榜数据
      home.rankinggetRanking(this.checktable).then((res) => {
        this.list = res.data.rankGroup;
        this.rankConfigList = res.data.rankConfigList;
        this.listperiod = res.data;
      });
    },
    tablecheck(index) {
      //上期
      this.checktable = index;
      this.rankinggetRanking();
      this.startrankinggetRankingData();
      this.pailist = [];
    },
    closeHandle() {
      const that = this;
      that.rules = !that.rules;
    },
  },
};
</script>
<style scoped>
.content {
  width: 100vw;
  min-height: 100vh;
}
.zzhead {
  width: 100vw;
  height: 300px;
  background: url("https://ppyos.xijiuyou.com/imgs/ranking-bg.png")
    no-repeat;
  padding-top: 165px;
  background-size: 100%;
}
.headtime {
  margin-bottom: 21px;
  background: url("https://ppyos.xijiuyou.com/imgs/blue-banner.png")
    no-repeat;
  background-size: 100% 100%;
  width: 234px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 22px;
  height: 22px;
  text-align: center;
}
.headtitle {
  width: calc(100vw - 24px);
  height: 142px;
  background: #ffffff;
  border-radius: 15px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.headtitle div {
  text-align: center;
}
.headtitle div:nth-child(2) {
  width: 1px;
  height: 52px;
  background: #dddddd;
}
.headtitle div p:nth-child(1) {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.headtitle div p:nth-child(1) img {
  width: 14px;
  height: 14px;
}
.headtitle div p:nth-child(2) {
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
}
.headtitle div:nth-child(1) p:nth-child(2) {
  font-size: 28px;
  color: #ff8028;
  height: 28px;
  line-height: 28px;
}
.headtitle div:nth-child(1) p:nth-child(2) {
  font-size: 28px;
  color: #ff8028;
}
.headtitle div:nth-child(1) p:nth-child(3) {
  width: 108px;
  height: 32px;
  background: #ff8028;
  border-radius: 22px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 32px;
  margin-top: 16px;
}
.headtitle div:nth-child(3) p:nth-child(3) {
  width: 108px;
  height: 32px;
  background: rgba(255, 128, 40, 0.1);
  border-radius: 22px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ff8028;
  text-align: center;
  line-height: 32px;
  margin-top: 16px;
}
.listranking {
  margin: 64px auto 0;
  width: calc(100vw - 24px);
  background: #ffffff;
  border-radius: 15px;
  padding: 14px 0;
}
.listrankingtitle {
  height: 32px;
  background: #f5f5f5;
  border-radius: 16px;
  margin: 0 auto 0;
  width: calc(100% - 36px);
  display: flex;
  margin-bottom: 20px;
}
.listrankingtitle p {
  width: 50%;
  height: 100%;
  text-align: center;
  line-height: 32px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.rankingtop {
  display: flex;
  justify-content: space-between;
  padding: 0 9px;
  box-sizing: border-box;
  align-items: flex-end;
}
.rankingtop div {
  text-align: center;
  box-sizing: border-box;
  margin: 0 2px;
}
.rankingtop div img {
  width: 18px;
  height: 18px;
  margin-top: -3px;
  margin-left: 3px;
}
.rankingtop div p {
  display: flex;
  align-items: center;
  font-size: 11px;
  justify-content: center;
}
.rankingtop div:nth-child(1) {
  width: 102px;
  height: 109px;
  padding-top: 40px;
  font-size: 12px;
  font-family: DINOffcPro-Medi, DINOffcPro;
  font-weight: normal;
  color: #5f6285;
  background: url("https://ppyos.xijiuyou.com/210913/rank-2.png")
    no-repeat;
  background-size: 100% 100%;
}
.rankingtop div:nth-child(1) p:nth-child(2) {
  font-size: 18px;
  font-family: DINOffcPro-Bold, DINOffcPro;
  font-weight: bold;
}
.rankingtop div p {
  margin-bottom: 11px;
}
.rankingtop div:nth-child(2) {
  width: 121px;
  height: 130px;
  padding-top: 55px;
  background: url("https://ppyos.xijiuyou.com/210913/rank-1.png")
    no-repeat;
  background-size: 100% 100%;
}
.rankingtop div:nth-child(2) p {
  font-size: 12px;
  font-family: DINOffcPro-Medi, DINOffcPro;
  font-weight: normal;
  color: #a36e3e;
}
.rankingtop div:nth-child(2) p:nth-child(2) {
  font-size: 18px;
  font-weight: bold;
}
.rankingtop div:nth-child(3) {
  width: 102px;
  height: 101px;
  padding-top: 40px;
  background: url("https://ppyos.xijiuyou.com/210913/rank-3.png")
    no-repeat;
  background-size: 100% 100%;
}

.rankingtop div:nth-child(3) p {
  font-size: 12px;
  font-family: DINOffcPro-Medi, DINOffcPro;
  font-weight: normal;
  color: #c1725c;
}
.rankingtop div:nth-child(3) p:nth-child(2) {
  font-size: 18px;
  font-weight: bold;
}
.fever {
  width: 108px;
  height: 78px;
  background: linear-gradient(180deg, #ffeee4 0%, #fff3ed 77%, #ffffff 100%);
  border-radius: 10px 10px 0px 0px;
  font-size: 12px;
  font-family: DINOffcPro-Medi, DINOffcPro;
  font-weight: normal;
  color: #c1725c;
}
.fever div {
  width: 28px;
  height: 28px;
  background: linear-gradient(
    180deg,
    rgba(245, 195, 177, 0.23) 0%,
    rgba(231, 144, 121, 0.23) 100%
  );
  margin: -12px auto 0;
  border-radius: 50%;
  text-align: center;
  line-height: 25px;
  font-size: 13px;
  font-family: DINOffcPro-Black, DINOffcPro;
  font-weight: 900;
  color: #ffffff;
  padding-top: 3px;
  padding-left: 3px;
}
.fever div div {
  width: 22px;
  height: 22px;
  padding: 0;
  margin: 0;
  background: linear-gradient(180deg, #f5c3b1 0%, #e79079 100%);
}
.fivetop {
  display: flex;
  padding: 0 9px;
  margin-top: 21px;
  margin-bottom: 21px;
  justify-content: space-between;
}
.fever {
  margin: 0 2px;
}
.fever p {
  display: flex;
  justify-content: center;
  align-items: center;
}
.fever p img {
  width: 18px;
  height: 18px;
  margin-top: -3px;
  margin-left: 4px;
}
.fever p:nth-child(2) {
  margin-top: 9px;
}
.fever p:nth-child(3) {
  font-size: 18px;
  font-family: DINOffcPro-Bold, DINOffcPro;
  font-weight: bold;
  color: #c1725c;
}

.rankingover {
  width: 80px;
  height: 78px;
}
.ranking-list {
  height: 44px;
  background: #f6f6f6;
  border-radius: 15px 15px 0px 0px;
  width: calc(100% - 20px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  box-sizing: border-box;
  margin: 0 auto;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  margin-top: 8px;
}
.ranking-list img {
  width: 14px;
  height: 14px;
}
.ranking-list .rightimg {
  width: 10px;
  height: 6px;
  margin-left: 9px;
}
.ranking-list div:nth-child(2) {
  display: flex;
  align-items: center;
}
.ranking-list div span {
  color: rgba(255, 128, 40, 1);
}
.Refresh {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  text-align: center;
  margin-top: 14px;
}
.over {
  background: #ffffff;
  border-radius: 15px;
  width: calc(100vw - 24px);
  margin: 10px auto 0;
  padding: 20px 16px 16px;
  box-sizing: border-box;
}
.overhead {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.overhead img {
  height: 2px;
  width: 30px;
}
.overhead p {
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ff5675;
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.overbottom {
  width: 90px;
  height: 113px;
  background: url("https://ppyos.xijiuyou.com/210913/redpack-origin.png")
    no-repeat;
  background-size: 100% 100%;
  padding-top: 17px;
  text-align: center;
  margin-bottom: 12px;
}
.overbottom p:nth-child(1) {
  display: flex;
  justify-content: center;
  align-items: center;
}
.overbottom p:nth-child(1) span:nth-child(1) {
  font-size: 18px;
  font-family: DINOffcPro-Medi, DINOffcPro;
  font-weight: normal;
  color: #be864f;
  margin-right: 5px;
}
.overbottom p:nth-child(1) span {
  font-size: 24px;
  font-family: DINOffcPro-Bold, DINOffcPro;
  font-weight: bold;
  color: #be864f;
  height: 31px;
  display: inline-block;
  line-height: 31px;
}
.overbottom p:nth-child(2) {
  width: 77px;
  height: 24px;
  background: #cc7e20;
  border-radius: 12px;
  opacity: 0.49;
  font-size: 12px;
  font-family: DINOffcPro-Bold, DINOffcPro;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  line-height: 24px;
  margin: 29px auto 0;
}
.rule {
  position: absolute;
  width: 24px;
  height: 48px;
  background: #b4fffc;
  border-radius: 8px 0px 0px 8px;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #221c8e;
  opacity: 0.6;
  right: 0;
  padding: 0 3px;
  top: 112px;
  text-align: center;
  line-height: 24px;
}
.rulelist {
  width: calc(100vw - 70px);
  margin: 50px auto 0;
  background: #ffffff;
  border-radius: 15px;
}
.rulelisthead {
  display: flex;
  width: 100%;
  height: 54px;
  justify-content: space-between;
  padding-left: 40px;
  background: linear-gradient(270deg, #ffe5bb 0%, #fff9ed 100%);
  border-radius: 15px 15px 0px 0px;
}
.rulelisthead p {
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #9c572c;
  line-height: 54px;
}
.rulelisthead img {
  width: 156px;
  height: 93px;
  margin-top: -39px;
}
.rulep {
  padding: 16px 26px;
  height: 400px;
  overflow-y: auto;
}
.rulep p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  text-align: left;
  display: flex;
  line-height: 25px;
}
.rulep p span:nth-child(1) {
  font-size: 14px;
  font-family: DINOffcPro-Bold, DINOffcPro;
  font-weight: bold;
  color: #ff5675;
  margin-right: 10px;
  display: inline-block;
}
.rulep p span:nth-child(2) {
  display: inline-block;
}
.listarr {
  width: calc(100% - 21px);
  height: 43px;
  padding: 0 15px;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f6f6f6;
  border-top: 1px solid rgba(229, 229, 229, 1);
}
.listarr:last-of-type {
  border-radius: 0 0 15px 15px;
}
.listarr div:nth-child(1) {
  display: flex;
  font-size: 14px;
  font-family: DINOffcPro-Medi, DINOffcPro;
  font-weight: normal;
  color: #333333;
  align-items: center;
}
.listarr div:nth-child(2) {
  font-size: 14px;
  font-family: DINOffcPro-Medi, DINOffcPro;
  font-weight: normal;
  color: #333333;
  display: flex;
}
.listarr div p:nth-child(1) {
  width: 30px;
  height: 18px;
  background: #d8d8d8;
  border-radius: 9px;
  text-align: center;
  font-size: 13px;
  font-family: DINOffcPro-Medi, DINOffcPro;
  font-weight: normal;
  color: #666666;
  margin-right: 10px;
}
.listarr div img {
  width: 16px;
  height: 16px;
}
.gengduo {
  width: calc(100% - 21px);
  height: 43px;
  background: #f6f6f6;
  border-radius: 0 0 15px 15px;
  margin: 0 auto;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ff8028;
  line-height: 43px;
  text-align: center;
  border-top:1px solid rgba(229, 229, 229, 1);
}
.overcontentbottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
</style>
